/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
    --red-color: #BB141C;
    --bg-opacity: rgba(225,58,66,1);
    --bg-x-light : rgba(255, 255, 255, .1);
    --red-light-color : #FDEDED;
    --gray-color: #707070;
    --gray-light-color: #F5F5F5;
    --dark-color: #272727;
    --border-radius: 3px;
}

html{
  font-size: 16px;
}

body {
  font-family: "Lato", sans-serif;
  color: var(--dark-color);
}


body:has(.navbar-collapse.show) .overlay,
body:has(.navbar-collapse.collapsing) .overlay{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 2;
  opacity: 0.75;
}

hr{
  border-top: 2px solid var(--gray-light-color);
  opacity: 1;
}

input[type='checkbox'] {
  accent-color: var(--red-color);
}

.tooltip-inner{
  font-family: "Lato", sans-serif;
  font-size: 0.85rem;
  padding: 0.5rem;
}

/* ----------------------------  Spacing --------------------------------- */

@media screen and (min-width: 1200px) {
  .gap-6 {
     gap:4em;
  }
}

/* ----------------------------  End Spacing --------------------------------- */

/* ----------------------------  Color --------------------------------- */

.red-color {
    color:var(--red-color);
}

.red-light-color{
    color:var(--red-light-color);
}

.bg-light{
  background-color: var(--gray-light-color) !important;
}

.gray-color {
    color: var(--gray-color);
}

.gray-light-color {
    color: var(--gray-light-color);
}

.dark-color {
    color:var(--dark-color);
}

.red-color {
  color:var(--red-color);
}

/* ----------------------------  End Color  --------------------------------- */

/* ----------------------------  Background-color --------------------------------- */

.bg-red-color {
    background-color: var(--red-color);
}

.bg-red-light-color {
    background-color:var(--red-light-color);
}

.bg-gray-color {
    background-color: var(--gray-color);
}

.bg-light-gray-color {
     background-color: var(--gray-light-color);
}

.bg-dark-color {
    background-color:var(--dark-color);
}

.bg-x-light {
  background-color: var(--bg-x-light);
}

/* ----------------------------  End Bg Color --------------------------------- */

/* ----------------------------  Font - Lato  --------------------------------- */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, p{
  line-height: 1.55;
}

.lato-thin {
    font-weight: 100;
    font-style: normal;
  }

  .lato-light {
    font-weight: 300;
    font-style: normal;
  }

  .lato-regular {
    font-weight: 400;
    font-style: normal;
  }

  .lato-bold{
    font-weight: 700;
    font-style: normal;
  }

  .lato-black, strong, .title   {
    font-weight: 800;
    font-style: normal;
  }

  .lato-thin-italic {
    font-weight: 100;
    font-style: italic;
  }

  .lato-light-italic {
    font-weight: 300;
    font-style: italic;
  }

  .lato-regular-italic {
    font-weight: 400;
    font-style: italic;
  }

  .lato-bold-italic {
    font-weight: 700;
    font-style: italic;
  }

  .lato-black-italic {
    font-weight: 900;
    font-style: italic;
  }

  .fw-bold{
    font-weight: 800 !important;
  }

  textarea.form-control, textarea:focus.form-control{
    font-size: 1rem !important;
    color: var(--dark-color);
    border:1px solid var(--gray-color);
  }

  .border-radius{
    border-radius: var(--border-radius) !important;
  }

/* ----------------------------End Font - Lato ------------------------------ */

/* ----------------------------  Mega Menu  --------------------------------- */

  .navbar .megamenu{ padding: 1rem; }

  /* ============ desktop view ============ */
  @media all and (min-width: 992px) {
    .navbar .has-megamenu{position:static!important;}
    .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }
  }
  /* ============ desktop view .end// ============ */

  /* ============ mobile view ============ */
  @media(max-width: 991px){
    .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
      overflow-y: auto;
        max-height: 90vh;
        margin-top:10px;
    }
  }
  /* ============ mobile view .end// ============ */

  /* ----------------------------End Mega Menu ------------------------------ */

  /* ----------------------------List utilities ------------------------------ */
  .list_no_style {
    list-style: none;
  }
  ::marker{
    color: var(--gray-color);
  }
  /* ----------------------------End  List Utilities ------------------------------ */

  /* ----------------------------  Utilities  --------------------------------- */
  @media all and (min-width: 768px) {
      .flex-basis-25 {
        flex-basis:25%;
      }
      .w-80 {
        max-width: 80%;
      }
      .min-h-150 {
        min-height: 150px;
      }
      .b-left {
        border-left:3px solid var(--gray-light-color);
      }
      .pr-md-0{
        padding-right: 0!important;
      }
  }
  .border_bottom{
    border-bottom: 2px solid var(--gray-light-color);
  }

  .b-0 {
    border:0;
    outline: 0;
  }

  .large {
    font-size:1.2em;
  }

  .xx-large{
    font-size:2em;
  }

  .small{
    font-size: 1em;
  }
  .x-small{
    font-size: .9em;
  }
  .xx-small{
    font-size: .7em;
  }
  .border_radius_left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .border_radius_right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .overflow_hidden {
    overflow: hidden;
  }

  .pointer {
    cursor:pointer;
  }

  /* ---------------------------- End Utilities  --------------------------------- */

  /* ------------------------------- Form   -------------------------------------- */
  .label_filtri {
    cursor: pointer;
  }

  .form-control, .form-control:focus{
    box-shadow: none;
    border:0;
    outline: 0;
    padding:10px;

  }
  /* ------------------------------- End Form  ------------------------------------ */

/* ------------------------------- Pills   -------------------------------------- */
.nav-pills-custom {
    border-bottom: 2px solid var(--gray-light-color);
}

.nav-pills-custom .btn_pills.active {
    border-bottom:4px solid #000;
    color:#000;
}

.nav-pills-custom .btn_pills{
    border:0;
    outline: 0;
    background: #fff;
    font-weight: 700;
    color:var(--gray-color);
}

/* ------------------------------- End Pills   -------------------------------------- */

.no-results{
  padding: 15vh 0;
  text-align: center;
  font-weight: bold;
}