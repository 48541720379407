.calendar {
  padding: 10px 5px;
}

.container.amp-plugin.show{
  font-family: "Lato", sans-serif;
  top: 2px !important;
  box-shadow: 5px 5px 20px rgba(112, 112, 112, 0.2);
}

.calendar>.days-grid>.day,
.container.amp-plugin .calendars .calendar>.header .month-name select{
  font-size: 1rem;
  color: var(--dark-color);
  outline: 0;
}

.calendar>.days-grid>.day.today{
  color: var(--dark-color);
  font-weight: 800;
}

.calendar>.days-grid>.day.selected{
  background-color: var(--red-color);
  font-weight: 800;
  color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--red-color);
}

.calendar>.days-grid>.day:not(.selected):hover {
  border: 1px solid var(--red-color);
  color: unset;
}

.calendar>.days-grid>.day{
  width: 38px;
  min-width: 38px;
  max-width: 38px;
}

.calendar>.header,
.calendar>.header,
.calendar>.days-grid{
  padding: 0 5px;
}

.calendar>.header button:hover>img, .calendar>.header button:hover>svg {
  fill: var(--dark-color);
  color: var(--dark-color);
}
